/* istanbul ignore file */
import { genericFilterOptions } from './filterOptions'

const filterDefinitions = (state) => [
  {
    name: 'search',
    label: 'Search',
    type: 'string-filter',
    humanReadableString: 'Text search is'
  },
  {
    component: 'CheckboxFilter',
    name: 'network',
    label: 'Networks',
    type: 'checkbox-filter',
    table: 'EIBIC_networks',
    options: genericFilterOptions('EIBIC_networks', 'network'),
    initiallyCollapsed: !state.route.query.network,
    filters: state.filters.selections.network,
    maxVisibleOptions: 25,
    humanReadableString: 'Networks:'
  },
  {
    component: 'CheckboxFilter',
    name: 'country',
    label: 'Countries',
    type: 'checkbox-filter',
    table: 'EIBIC_countries',
    options: genericFilterOptions('EIBIC_countries', 'country'),
    initiallyCollapsed: !state.route.query.country,
    filters: state.filters.selections.country,
    maxVisibleOptions: 25,
    humanReadableString: 'Countries:'
  },
  // {
  //   component: 'CheckboxFilter',
  //   name: 'body_part_examined',
  //   label: 'Body parts',
  //   type: 'checkbox-filter',
  //   table: 'EIBIC_body_parts',
  //   options: genericFilterOptions('EIBIC_body_parts', 'body_part_examined'),
  //   initiallyCollapsed: !state.route.query.bodyPart,
  //   filters: state.filters.selections.bodyPart,
  //   satisfyAll: state.filters.satisfyAll.includes('body_part_examined'),
  //   showSatisfyAllCheckbox: true,
  //   maxVisibleOptions: 10,
  //   humanReadableString: 'Body part(s):'
  // },
  {
    component: 'CheckboxFilter',
    name: 'body_parts',
    label: 'Body parts',
    type: 'checkbox-filter',
    table: 'EUCAIM_body_parts',
    options: genericFilterOptions('EIBIC_body_parts', 'body_parts'),
    initiallyCollapsed: !state.route.query.bodyPart,
    filters: state.filters.selections.bodyPart,
    satisfyAll: state.filters.satisfyAll.includes('body_parts'),
    showSatisfyAllCheckbox: true,
    maxVisibleOptions: 10,
    humanReadableString: 'Body part(s):'
  },
  {
    component: 'CheckboxFilter',
    name: 'imaging_modality',
    label: 'Imaging modalities',
    type: 'checkbox-filter',
    table: 'EIBIC_imaging_modality',
    options: genericFilterOptions('EIBIC_imaging_modality', 'imaging_modality'),
    initiallyCollapsed: !state.route.query.imaging_modality,
    filters: state.filters.selections.imaging_modality,
    satisfyAll: state.filters.satisfyAll.includes('imaging_modality'),
    showSatisfyAllCheckbox: true,
    maxVisibleOptions: 25,
    humanReadableString: 'Image modality:'
  },
  {
    component: 'CheckboxFilter',
    name: 'type',
    label: 'Collection types',
    type: 'checkbox-filter',
    table: 'EIBIC_collection_types',
    options: genericFilterOptions('EIBIC_collection_types', 'type'),
    initiallyCollapsed: !state.route.query.type,
    filters: state.filters.selections.type,
    satisfyAll: state.filters.satisfyAll.includes('type'),
    showSatisfyAllCheckbox: true,
    maxVisibleOptions: 25,
    humanReadableString: 'Collection type(s):'
  },
  {
    component: 'CheckboxFilter',
    name: 'dataType',
    label: 'Dataset types',
    type: 'checkbox-filter',
    table: 'EIBIC_data_types',
    options: genericFilterOptions('EIBIC_data_types', 'dataType'),
    initiallyCollapsed: !state.route.query.dataType,
    filters: state.filters.selections.dataType,
    satisfyAll: state.filters.satisfyAll.includes('dataType'),
    showSatisfyAllCheckbox: true,
    maxVisibleOptions: 25,
    humanReadableString: 'Data type(s):'
  },
  {
    component: 'CheckboxFilter',
    name: 'image_access_type',
    label: 'Image access types',
    type: 'checkbox-filter',
    table: 'EIBIC_image_access_type',
    options: genericFilterOptions('EIBIC_image_access_type', 'image_access_type'),
    initiallyCollapsed: !state.route.query.image_access_type,
    filters: state.filters.selections.image_access_type,
    satisfyAll: state.filters.satisfyAll.includes('image_access_type'),
    showSatisfyAllCheckbox: true,
    maxVisibleOptions: 25,
    humanReadableString: 'Image Access type:'
  },
  {
    component: 'CheckboxFilter',
    name: 'sex',
    label: 'Sex',
    type: 'checkbox-filter',
    table: 'EIBIC_sex',
    options: genericFilterOptions('EIBIC_sex', 'sex'),
    initiallyCollapsed: !state.route.query.sex,
    filters: state.filters.selections.sex,
    satisfyAll: state.filters.satisfyAll.includes('sex'),
    showSatisfyAllCheckbox: true,
    maxVisibleOptions: 25,
    humanReadableString: 'Sex:'
  },
  {
    component: 'CheckboxFilter',
    name: 'risk_factors',
    label: 'Risk factors',
    type: 'checkbox-filter',
    table: 'EIBIC_risk_factors',
    options: genericFilterOptions('EIBIC_risk_factors', 'risk_factors'),
    initiallyCollapsed: !state.route.query.risk_factors,
    filters: state.filters.selections.risk_factors,
    satisfyAll: state.filters.satisfyAll.includes('risk_factors'),
    showSatisfyAllCheckbox: true,
    maxVisibleOptions: 25,
    humanReadableString: 'Risk factor(s):'
  },
  {
    component: 'CheckboxFilter',
    name: 'treatment',
    label: 'Treatment',
    type: 'checkbox-filter',
    table: 'EIBIC_treatment',
    options: genericFilterOptions('EIBIC_treatment', 'treatment'),
    initiallyCollapsed: !state.route.query.treatment,
    filters: state.filters.selections.treatment,
    satisfyAll: state.filters.satisfyAll.includes('treatment'),
    showSatisfyAllCheckbox: true,
    maxVisibleOptions: 25,
    humanReadableString: 'Treatment:'
  },
  {
    component: 'CheckboxFilter',
    name: 'supporting_data',
    label: 'Supporting data',
    type: 'checkbox-filter',
    table: 'EIBIC_supporting_data',
    options: genericFilterOptions('EIBIC_supporting_data', 'supporting_data'),
    initiallyCollapsed: !state.route.query.supporting_data,
    filters: state.filters.selections.supporting_data,
    satisfyAll: state.filters.satisfyAll.includes('supporting_data'),
    showSatisfyAllCheckbox: true,
    maxVisibleOptions: 25,
    humanReadableString: 'Supporting data:'
  }
]

export default filterDefinitions
