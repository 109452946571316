<template>
  <div class="container mg-collection-report-card">
    <script v-text="bioschemasJsonld" type="application/ld+json" />
    <loading
      :active="isLoading"
      loader="dots"
      :is-full-page="true"
      color="var(--secondary)"
      background-color="var(--light)"></loading>
    <div class="container-fluid">
      <!-- Back to previous page buttons -->
      <button class="btn btn-link pl-0" @click="back">
        <i class="fa fa-angle-left" aria-hidden="true"></i> Back
      </button>

      <div class="row" v-if="this.collection && !this.isLoading">
        <div class="col">
          <report-title type="Collection" :name="collection.name">
          </report-title>

          <div class="container p-0">
            <div class="row">
              <div class="col-md-8">
                <report-collection-details :collection="collection" />
              </div>

              <!-- Right side card -->
              <collection-report-info-card
                :info="info"></collection-report-info-card>
            </div>
            <div v-if="rawData.items.length > 0" >
            <h4 class="row justify-content-center pt-5 pb-2"> Original Datasets</h4>
                <table class="table table-hover mt-1">
                    <thead>
                      <tr>
                        <th scope="col" style="width: 40%">Image type</th>
                        <th scope="col" style="width: 20%">Body part</th>
                        <th scope="col" style="width: 20%"># timepoints</th>
                        <th scope="col" style="width: 20%"># subjects</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="items in rawData.items" :key="items">
                        <td>{{ items.type_of_raw_dataset.raw_dataset_type }}</td>
                        <td>{{ items.body_region_examined[0].label }}</td>
                        <td>{{ items.number_of_timepoints }}</td>
                        <td>{{ items.number_of_raw_datasets }}</td>
                      </tr>
                    </tbody>
                </table>
                </div>
                <div v-else>
                  <h4 class="row justify-content-center pt-5 pb-2"> No Original Datasets have been found</h4>
                </div>
                <div v-if="annotatedData.items.length > 0" >
                <h4 class="row justify-content-center pt-5 pb-2"> Annotated Datasets</h4>
                <table class="table table-hover mt-1">
                    <thead>
                      <tr>
                        <th scope="col" style="width: 15%">Description</th>
                        <th scope="col" style="width: 10%">Body Part</th>
                        <th scope="col" style="width: 15%">Pathological target</th>
                        <th scope="col" style="width: 25%">Method specification</th>
                        <th scope="col" style="width: 10%">Method publication</th>
                        <th scope="col" style="width: 15%">Biomarker information</th>
                        <th scope="col" style="width: 10%"># subjects</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="items in annotatedData.items" :key="items">
                        <td v-if="items.extracted_biomarkers_available==true && items.annotations_available==true">{{ items.annotated_dataset_description }} <b>with extracted biomarkers and annotations</b></td>
                        <td v-else-if="items.annotations_available==true">{{ items.annotated_dataset_description }} <b>with annotations</b></td>
                        <td v-else-if="items.extracted_biomarkers_available==true">{{ items.annotated_dataset_description }} <b>with extracted biomarkers</b></td>
                        <td v-else>{{ items.annotated_dataset_description }}</td>
                        <td>{{ items.body_region_examined[0].label }}</td>
                        <td>{{ items.pathological_target.dataset_pathological_target }}</td>
                        <td>{{ items.method_specification }}</td>
                        <td> <a :href="items.method_publication" target="_blank" rel="noopener noreferrer"><span>Publication</span></a></td>
                        <td>{{ items.additional_biomarker_information }}</td>
                        <td>{{ items.number_of_annotated_datasets }}</td>
                      </tr>
                    </tbody>
                </table>
                </div>
                <div v-else>
                  <h4 class="row justify-content-center pt-5 pb-2"> No Annotated Datasets have been found</h4>
                </div>
                <div v-if="processedData.items.length > 0" >
                <h4 class="row justify-content-center pt-5 pb-2"> Processed Datasets</h4>
                <table class="table table-hover mt-1">
                    <thead>
                      <tr>
                        <th scope="col">Type of process</th>
                        <th scope="col">Description</th>
                        <th scope="col"># subjects</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="items in processedData.items" :key="items">
                        <td>{{ items.type_of_processed_dataset[0].processed_dataset_type }}</td>
                        <td>{{ items.processed_dataset_description }}</td>
                        <td>{{ items.number_of_processed_datasets }}</td>
                      </tr>
                    </tbody>
                </table>
                </div>
                <div v-else>
                  <h4 class="row justify-content-center pt-5 pb-2"> No Processed Datasets have been found</h4>
                </div>
                <div class = "mb-5">
                </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import ReportTitle from '../components/report-components/ReportTitle'
import CollectionReportInfoCard from '../components/cards/CollectionReportInfoCard_EIBIC'
import { collectionReportInformation } from '../utils/templateMapper'
import { mapCollectionToBioschemas } from '../utils/bioschemasMapper'
import ReportCollectionDetails from '../components/report-components/ReportCollectionDetails_EIBIC.vue'

export default {
  name: 'CollectionReport',
  components: {
    ReportTitle,
    CollectionReportInfoCard,
    Loading,
    ReportCollectionDetails
  },
  methods: {
    ...mapActions(['GetCollectionReport', 'GetRawDataSetReport', 'GetAnnotatedDataSetReport', 'GetProcessedDataSetReport']),
    back () {
      this.$router.go(-1)
    }
  },
  computed: {
    ...mapState({ collection: 'collectionReport', isLoading: 'isLoading', rawData: 'rawDataSetReport', processedData: 'processedDataSetReport', annotatedData: 'annotatedDataSetReport' }),
    info () {
      return collectionReportInformation(this.collection)
    },
    collectionId () {
      const splittedUrl = this.$route.fullPath.split('/')
      return splittedUrl[splittedUrl.length - 1]
    },
    bioschemasJsonld () {
      return this.collection ? mapCollectionToBioschemas(this.collection) : {}
    }
  },
  // needed because if we route back the component is not destroyed but its props are updated for other collection
  watch: {
    $route (to, from) {
      if (from.name.indexOf('collection') >= 0) {
        location.reload()
      }
    }
  },
  mounted () {
    this.GetCollectionReport([this.collectionId])
    this.GetRawDataSetReport([this.collectionId])
    this.GetAnnotatedDataSetReport([this.collectionId])
    this.GetProcessedDataSetReport([this.collectionId])
  }
}
</script>
